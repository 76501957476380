<template>
	<div class="docs">
		<div class="view-header">
			<img class="view-header__image" src="@/assets/img-c1-docs-01.png">
			<h1 class="title" v-markdown:title>
				zione.sport *Docs* 2.0
			</h1>
			<p class="subtitle view-header__subtitle">
				Una solución innovadora, flexible y segura para el almacenamiento y gestión de documentos
			</p>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";
	
	export default {
		name: "Docs",
		components: {
			sectionHandler
		},
		data() {
			return {
				sections: content.docs
			}
		}
	}
</script>