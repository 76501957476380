<template>
	<div class="torneos">
		<div class="view-header">
			<h3 class="view-header__legend"></h3>
			<h1 class="title">zione.sport <span class="text--highlighted">Torneos</span></h1>
			<p class="view-header__description">
				Si administras una liga deportiva, tienes que usar zione.sport
			</p>
			<screencast-play-btn/>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
		<faq topic="SignOn"></faq>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";
	import screencastPlayBtn from "@/components/ScreencastPlayBtn";
	import faq from "@/components/FaqCompact.vue";

	export default {
		name: "Torneos",
		components: {
			sectionHandler,
			screencastPlayBtn,
			faq
		},
		data() {
			return {
				sections: content.torneos
			}
		}
	}
</script>