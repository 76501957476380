<template>
	<div class="saldos">
		<div class="view-header">
			<h3 class="view-header__legend">
				Administración eficaz
			</h3>
			<h1 class="title">zione.sport <span class="text--highlighted">Saldos</span></h1>
			<p class="view-header__description">
				La rentabilidad de tus torneos comienza aquí.
			</p>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";
	
	export default {
		name: "Saldos",
		components: {
			sectionHandler
		},
		data() {
			return {
				sections: content.saldos
			}
		}
	}
</script>