<template>
	<div class="page-section">
		<div class="faq-compact" :class="$responsive">
				<h2 class="title faq__title">Preguntas frecuentes</h2>
				<ul class="faq-segment">
					<li class="faq-segment__item" v-for="(item, index) in items" :key="index">
						<div class="faq-question">
							{{ item.question }}
						</div>
						<div class="faq-answer">
							<p v-markdown:text>{{ item.answer }}</p>
						</div>	
					</li>
				</ul>
				<div class="faq-compact__link">
					<router-link :to="{ name: 'faq' }">
							Más información en Preguntas Frecuentes
					</router-link>
				</div>
		</div>
	</div>	
</template>

<script>
	import content from "@/content.json";
	export default {
		name: "FaqCompact",
		props: ['topic'],
		computed: {
			items() {
				const segment = content.frequentQuestions.filter(segment => segment.topic === this.topic);
				return segment.length
					? segment[0].items.slice(0, 3)
					: [];
			}
		}
	}
</script>

<style scoped>
.faq-compact {
	margin-top: 17.5vh;
	text-align: left;
}

.faq-compact.mobile {
	margin-top: 30vh;
}

.faq__title {
	margin-bottom: 2.25rem;
	font-size: 1.75rem;
}

.mobile .faq__title {
	font-size: 1.5rem;
}

.faq-segment {
	display: flex;
	justify-content: space-between;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: .01em;
	font-family: "helvetica neue", sans-serif;
}

.sm-view .faq-segment {
	flex-wrap: wrap;
}

.faq-segment__item {
	flex-basis: 30%;
}

.sm-view .faq-segment__item {
	flex-basis: 100%;
}

.faq-question {
	font-weight: 600;
	font-size: 1.0625em;
}

.faq-answer {
	color: var(--secondary-color);
}

.faq-compact__link {
	margin-top: 1.5rem;
}

.faq-compact__link > a {
	color: inherit;
	font-family: 'Open Sans', sans-serif;
	font-size: .8125rem;
	text-decoration: none;
	border-bottom: 1px solid rgba(0, 0, 0, .26);
	padding-bottom: .0625rem;
}

.faq-compact__link > a:hover {
	border-bottom: 1px solid rgba(34, 34, 34, .4);
}
</style>