<template>
	<div class="products">
		<div class="view-header">
			<h3 class="view-header__legend">
				Nuestros Productos
			</h3>
			<h1 class="title" v-markdown:title>
				La manera *más fácil* de administrar tus *torneos* en línea
			</h1>
			<p class="view-header__description">
				Descubre todas las opciones que la plataforma te ofrece.
			</p>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";

	export default {
		name: "Products",
		components: {
			sectionHandler
		},
		data() {
			return {
				sections: content.products
			}
		}
	}
</script>