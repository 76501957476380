<template>
	<div class="coaches">
		<div class="view-header">
			<h3 class="view-header__legend">
				Consola de Coaches
			</h3>
			<h1 class="title" v-markdown:title>
				zione.sport *Coaches*
			</h1>
			<p class="view-header__description">
				Coaches - Entrenadores - Delegados - Capitanes
			</p>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";
	
	export default {
		name: "Coaches",
		components: {
			sectionHandler
		},
		data() {
			return {
				sections: content.coaches
			}
		}
	}
</script>