<template>
	<div class="apps">
		<div class="view-header">
			<h3 class="view-header__legend">
				EXPLORA LAS OPCIONES
			</h3>
			<h1 class="title" v-markdown:title>
				Nuestras *Apps*
			</h1>
			<p class="view-header__description" v-markdown:text>
				Con las apps de zione.sport, tus equipos tendrán todo al alcance de sus manos.
			</p>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";
	
	export default {
		name: "Apps",
		components: {
			sectionHandler
		},
		data() {
			return {
				sections: content.apps
			}
		}
	}
</script>